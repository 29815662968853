import { render, staticRenderFns } from "./homeIndex.vue?vue&type=template&id=a8640dea&scoped=true&"
import script from "./homeIndex.vue?vue&type=script&lang=js&"
export * from "./homeIndex.vue?vue&type=script&lang=js&"
import style0 from "./homeIndex.vue?vue&type=style&index=0&id=a8640dea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8640dea",
  null
  
)

export default component.exports